import { useEffect } from 'react'
import tippy from 'tippy.js'

const defaultOption = {
  arrow: true,
  animation: 'shift-away',
  offset: [0, 15],
}

export default function useTippy(selector, option = {}) {
  useEffect(() => {
    tippy(selector, Object.assign({}, defaultOption, option))
  }, [selector, option])
}
