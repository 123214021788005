import React from 'react'
import { Link } from 'gatsby'

export const Latest = ({ posts }) => (
  <section className="sidebar__item">
    <h2 className="sidebar__title">最新記事</h2>
    <ol className="sidebar__articles">
      {posts.map(({ node: latest }) => (
        <li className="sidebar__articles-item" key={latest.frontmatter.slug}>
          <Link to={`/${latest.frontmatter.slug}`} className="sidebar__article">
            <figure className="sidebar__article-eyecatch">
              <img src={latest.frontmatter.eyeCatch} loading="lazy" alt="" />
            </figure>
            <h3 className="sidebar__article-title">
              {latest.frontmatter.title}
            </h3>
          </Link>
        </li>
      ))}
    </ol>
    <Link to="/" className="box-link box-link--full">
      <span>記事一覧へ</span><i className="box-link__icon">→</i>
    </Link>
  </section>
)
