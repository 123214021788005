import React from 'react'
import IconTwitter from './icons/twitter'
import IconFacebook from './icons/facebook'
import IconPocket from './icons/pocket'
import IconFeedly from './icons/feedly'
import useTippy from '../hooks/tippy'

export default function Share({ url, title }) {
  useTippy('.share__link')

  const onCLickFB = (e) => {
    e.preventDefault()
    window.open(e.target.href, 'FBwindow', 'width=650, height=450, menubar=no, toolbar=no, scrollbars=yes')
  }

  return (
    <div className="share">
      <ul className="share__list">
        <li className="share__item">
          <a
            data-tippy-content="Twitter"
            className="share__link share__link--twitter"
            href={`https://twitter.com/share?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`}
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <IconTwitter />
          </a>
        </li>
        <li className="share__item">
          <a
            data-tippy-content="Facebook"
            className="share__link share__link--facebook"
            href={`https://www.facebook.com/share.php?u=${encodeURIComponent(url)}`}
            onClick={onCLickFB}
          >
            <IconFacebook />
          </a>
        </li>
        <li className="share__item">
          <a
            data-tippy-content="Pocket"
            className="share__link share__link--pocket"
            href={`https://getpocket.com/edit?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`}
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <IconPocket />
          </a>
        </li>
        <li className="share__item">
          <a
            data-tippy-content="Feedly"
            className="share__link share__link--feed"
            href={`https://feedly.com/i/subscription/feed%2Fhttps%3A%2F%2Fwww.hypertextcandy.com%2Frss.xml`}
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <IconFeedly />
          </a>
        </li>
      </ul>
    </div>
  )
}
