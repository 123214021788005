const isSurrogatePear = function (upper, lower) {
  return 0xD800 <= upper && upper <= 0xDBFF && 0xDC00 <= lower && lower <= 0xDFFF
}

const strlen = function (str) {
  let res = 0;
  for (let i = 0; i < str.length; i++, res++) {
    const upper = str.charCodeAt(i);
    const lower = str.length > (i + 1) ? str.charCodeAt(i + 1) : 0
    isSurrogatePear(upper, lower) && i++
  }
  return res
}

const substr = function (str, start, length) {
  let res = ''
  for (let i = 0, len = 0; i < str.length; i++, len++) {
    const upper = str.charCodeAt(i)
    const lower = str.length > (i + 1) ? str.charCodeAt(i + 1) : 0
    let s = '';
    if (isSurrogatePear(upper, lower)) {
      i++
      s = String.fromCharCode(upper, lower)
    } else {
      s = String.fromCharCode(upper)
    }
    if (start <= len && len < length) {
      res += s
    }
  }
  return res
}

module.exports = {
  strlen,
  substr
}
