import React, { useState, useEffect } from 'react'

const randomId = () => Math.random().toString(36).replace(/[^a-z]+/g, '')

function useId() {
  const [id, setId] = useState('')

  useEffect(() => {
    setId(`tocTitle_${randomId()}`)
  }, [])

  return id
}

export default function MapIcon() {
  const id = useId()

  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id={id} x1="0%" y1="0%" x2="60%" y2="100%">
          <stop stopColor="#AC32E4" offset="0%" />
          <stop stopColor="#7918F2" offset="48%" />
          <stop stopColor="#4801FF" offset="100%" />
        </linearGradient>
      </defs>
      <g fill={`url(#${id})`}>
        <path fillRule="evenodd" d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.502.502 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103zM10 1.91l-4-.8v12.98l4 .8V1.91zm1 12.98l4-.8V1.11l-4 .8v12.98zm-6-.8V1.11l-4 .8v12.98l4-.8z"/>
      </g>
    </svg>
  )
}
