import React from 'react'
import classNames from 'classnames'

export const TocList = ({ content }) => (
  <ol className="toc__list">
    {content.map(item => (
      <li className={`toc__item toc__item--${item.level}`} key={item.id}>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <a
          href={`#${encodeURI(item.id)}`}
          dangerouslySetInnerHTML={{__html : item.text}}
        />
      </li>
    ))}
  </ol>
)

export const TocWrappper = ({ accordion, children }) => {
  const tocClassNames = ['toc']
  if (typeof accordion !== 'undefined') {
    tocClassNames.push('toc--accordion')
  }
  return <div className={classNames(tocClassNames)}>{children}</div>
}

export const TocTitle = ({ title, icon }) => (
  <h2 className="toc__title">
    {icon}
    <span>{title}</span>
  </h2>
)

const Toc = ({ content, title, icon }) => (
  <TocWrappper>
    <TocTitle title={title} icon={icon} />
    <TocList content={content} />
  </TocWrappper>
)

export default Toc
