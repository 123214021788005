import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import ArticleCell from '../components/article-cell'
import Pager from '../components/pager'
import Container from '../components/container'
import ScrollTop from '../components/scroll-top'
import Ad from '../components/ad'
import Logo from '../assets/logo.png'

export const ArticleList = ({ children }) => (
  <ol className="article-list">{children}</ol>
)

export const ArticleListItem = ({ children }) => (
  <li className="article-list__item">{children}</li>
)

export default function Index ({ data, pageContext }) {
  const { totalPages, currentPage } = pageContext
  const { edges: posts } = data.allMarkdownRemark

  return (
    <Layout fab={<ScrollTop />}>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          { name: 'description', content: data.site.siteMetadata.description },
          { name: 'og:title', content: data.site.siteMetadata.title },
          { name: 'og:type', content: 'article' },
          { name: 'og:description', content: data.site.siteMetadata.description },
          { name: 'og:url', content: data.site.siteMetadata.url },
          { name: 'og:image', content: Logo },
          { name: 'og:site_name', content: data.site.siteMetadata.title },
          { name: 'og:locale', content: 'ja_JP' },
        ]}
      />
      <Container>
        <main>
          <ArticleList>
            {
              posts
                .filter(post => post.node.frontmatter.title.length > 0)
                .map(({ node: post }) => (
                  <ArticleListItem key={post.frontmatter.slug}>
                    <ArticleCell
                      title={post.frontmatter.title}
                      href={`/${post.frontmatter.slug}`}
                      date={post.frontmatter.date}
                      author={post.frontmatter.author}
                      eyeCatch={post.frontmatter.eyeCatch}
                      tags={post.frontmatter.tags}
                    />
                  </ArticleListItem>
                ))
            }
          </ArticleList>
          <Pager base="/" current={currentPage} total={totalPages} />
        </main>
        {process.env.NODE_ENV !== 'development' && (
          <aside>
            <Ad
              client={data.site.siteMetadata.adsenseClientId}
              slot="1426003445"
              type="landscape"
            />
          </aside>
        )}
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Posts($public: [Boolean]!, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {
        frontmatter: { public: { in: $public } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "YYYY.MM.DD")
            slug
            author
            eyeCatch
            tags
          }
        }
      }
    }
    site {
      siteMetadata {
        url
        title
        description
        adsenseClientId
      }
    }
  }
`
