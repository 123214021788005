import React from 'react'
import { ArticleList, ArticleListItem } from '../templates/index'
import {
  ArticleCellWrapper,
  ArticleCellLink,
  ArticleCellPhoto,
  ArticleCellBody
} from './article-cell'

const RelatedPosts = ({ posts, title, titleIcon }) => (
  <aside className="related-posts">
    <h2 className="related-posts__title">
      {titleIcon}
      {title}
    </h2>
    <ArticleList>
      {posts.map(({ node }) => (
        <ArticleListItem key={node.frontmatter.slug}>
          <ArticleCellWrapper>
            <ArticleCellLink to={`/${node.frontmatter.slug}`}>
              <ArticleCellPhoto src={node.frontmatter.eyeCatch} />
              <ArticleCellBody title={node.frontmatter.title} />
            </ArticleCellLink>
          </ArticleCellWrapper>
        </ArticleListItem>
      ))}
    </ArticleList>
  </aside>
)

export default RelatedPosts
