const sanitize = require('sanitize-html')
const multibyte = require('./multibyte')

/**
 * Create excerpt from HTML content.
 * @param {string} htmlContent HTML
 * @param {int} length 抜粋する文字数
 */
function excerpt (htmlContent, length) {
  const clean = sanitize(htmlContent, {
    allowedTags: [],
    allowedAttributes: []
  }).replace(/\r?\n/g, ' ')

  return multibyte.substr(clean, 0, length)
}

module.exports = excerpt
