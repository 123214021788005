import React from 'react'

const IconFeedly = () => (
  <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
    <g id="surface1">
      <path d="M 20.027344 45 L 29.96875 45 C 31.5625 45 33.085938 44.367188 34.214844 43.242188 L 46.214844 31.242188 C 48.554688 28.898438 48.554688 25.101563 46.214844 22.757813 L 29.242188 5.785156 C 26.898438 3.445313 23.101563 3.445313 20.757813 5.785156 L 3.785156 22.757813 C 1.445313 25.101563 1.445313 28.898438 3.785156 31.242188 L 15.785156 43.242188 C 16.910156 44.367188 18.4375 45 20.027344 45 Z M 17.464844 33.605469 L 16.515625 32.65625 C 16.121094 32.265625 16.121094 31.632813 16.515625 31.242188 L 23.585938 24.171875 C 23.976563 23.78125 24.609375 23.78125 25 24.171875 L 27.121094 26.292969 C 27.511719 26.683594 27.511719 27.316406 27.121094 27.707031 L 21.21875 33.605469 C 21.03125 33.792969 20.777344 33.898438 20.511719 33.898438 L 18.171875 33.898438 C 17.90625 33.898438 17.652344 33.792969 17.464844 33.605469 Z M 27.828125 38.3125 L 26.878906 39.265625 C 26.691406 39.453125 26.4375 39.558594 26.171875 39.558594 L 23.828125 39.558594 C 23.5625 39.558594 23.308594 39.453125 23.121094 39.265625 L 22.171875 38.3125 C 21.78125 37.921875 21.78125 37.289063 22.171875 36.898438 L 24.292969 34.777344 C 24.683594 34.386719 25.316406 34.386719 25.707031 34.777344 L 27.828125 36.898438 C 28.21875 37.289063 28.21875 37.921875 27.828125 38.3125 Z M 10.859375 25.585938 L 23.585938 12.859375 C 23.976563 12.46875 24.609375 12.46875 25 12.859375 L 27.121094 14.980469 C 27.511719 15.371094 27.511719 16.003906 27.121094 16.394531 L 15.566406 27.949219 C 15.378906 28.136719 15.121094 28.242188 14.859375 28.242188 L 12.515625 28.242188 C 12.25 28.242188 11.996094 28.136719 11.808594 27.949219 L 10.859375 27 C 10.46875 26.609375 10.46875 25.976563 10.859375 25.585938 Z " />
    </g>
  </svg>
)

export default IconFeedly
