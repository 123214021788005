const sanitize = require('sanitize-html')

export function html2Toc (html) {
  const pattern = /<h([2-3])\s+.*id="([^<>"]+)">(.+)<\/h[2-3]>/g

  let match
  let toc = []

  while ((match = pattern.exec(html)) !== null) {
    toc.push({
      level: match[1],
      id: match[2],
      text: sanitize(match[3], {
        allowedTags: [],
        allowedAttributes: []
      })
    })
  }

  return toc
}
