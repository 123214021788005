import React from 'react'
import { DiscussionEmbed } from 'disqus-react'

function Disqus({ id, title }) {
  const config = { identifier: id, title }

  return <DiscussionEmbed shortname="hypertext-candy" config={config} />;
}

export default Disqus
