import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import jump from 'jump.js'
import Layout from '../components/layout'
import Tags from '../components/tags'
import WriterLink from '../components/writer-link'
import Share from '../components/share'
import Container, { ContainerRow, ContainerCol } from '../components/container'
import Divider from '../components/divider'
import { Latest } from '../components/sidebar'
import Toc from '../components/toc'
import TocAccordion from '../components/toc-accordion'
import RelatedPosts from '../components/related-posts'
import Disqus from '../components/disqus'
import Ad from '../components/ad'
import MapIcon from '../components/icons/map'
import { html2Toc } from '../../utils/toc'
import useFab from '../hooks/fab'
import useTippy from '../hooks/tippy'
import logoSmall from '../assets/logo-small.png'

import '../scss/components/_highlight.scss'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/shift-away.css'

const excerpt = require('../../utils/excerpt')
const EXCERPT_LENGTH = 100

const Title = ({ children }) => (
  <h1 className="article__title">{children}</h1>
)

const PubDate = ({ children }) => (
  <p className="article__pub-date">{children}</p>
)

const TwitterAccount = ({ name }) => (
  <a
    className="article__author-twitter"
    href={`https://twitter.com/${name}`}
    rel="noopener noreferrer"
    target="_blank"
  >@{name}</a>
)

const Author = ({ author }) => (
  <div className="article__author">
    <figure className="article__author-avatar">
      <img src={author.avatar} alt="" />
    </figure>
    <div>
      <WriterLink name={author.name} className="article__author-name" />
      {author.twitter && <TwitterAccount name={author.twitter} />}
    </div>
  </div>
)

const ArticleHeader = ({ children }) => (
  <div className="article__header">{children}</div>
)

const EyeCatch = ({ src }) => (
  <figure className="article__photo">
    <img src={src} alt="" />
  </figure>
)

const ArticleBody = ({ html }) => (
  <div className="article__body">
    <div
      className="content"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  </div>
)

const ArticleFooter = ({ children }) => (
  <div className="article__footer">{children}</div>
)

const Fab = () => {
  const className = useFab()

  useTippy('#fab', {
    content: '記事一覧へ',
    placement: 'left',
    delay: [300, null],
  })

  return (
    <Link id="fab" className={className} to="/">
      <img className="icon icon--lg" src={logoSmall} alt="" />
    </Link>
  )
}

function useTweetEmbed(html) {
  useEffect(() => {
    const tweets = document.querySelectorAll('.twitter-tweet')
    if (tweets.length === 0) {
      return
    }

    if (document.getElementById('twitter-script')) {
      window.twttr.widgets.load()
      return
    }

    const s = document.createElement('script')
    s.async = true
    s.src = 'https://platform.twitter.com/widgets.js'
    s.id = 'twitter-script'
    document.body.appendChild(s)
  }, [html])
}

function useCodepenEmbed(html) {
  useEffect(() => {
    // Add Codepen script to <body> if we detect a Codepen embed
    const codepen = document.querySelectorAll('.codepen')
    if (codepen.length === 0) {
      return
    }

    // Check if we've already embedded the script
    if (document.getElementById('codepen-script')) {
      window.__CPEmbed()
      return
    }

    // Create script element with Codepen embed JS lib
    const s = document.createElement('script')
    s.async = s.defer = true
    s.src = 'https://static.codepen.io/assets/embed/ei.js'
    s.id = 'codepen-script'
    document.body.appendChild(s)
  }, [html])
}

function useEmulateHashJump() {
  useEffect(() => {
    if (window.location.hash) {
      jump(decodeURI(window.location.hash), { duration: 300 })
    }
  }, [])
}

export default function Template({ data }) {
  const { site, markdownRemark: post, latestPosts } = data
  const pageUrl = `${site.siteMetadata.url}/${post.frontmatter.slug}`
  const pageTitle = `${post.frontmatter.title} | ${site.siteMetadata.title}`
  const author = data.authorsJson
  const toc = html2Toc(post.html)

  useEmulateHashJump()
  useTweetEmbed(post.html)
  useCodepenEmbed(post.html)

  // 関連記事
  // 最新記事には含まれていない記事を新しい順に12件選ぶ
  const relatedPosts = data.relatedPosts
    ? data.relatedPosts.edges.filter(({ node: relatedPost }) => {
        return !latestPosts.edges
          .map(({ node: latestPost }) => latestPost.frontmatter.slug)
          .includes(relatedPost.frontmatter.slug)
      }).slice(0, 12)
    : []

  return (
    <Layout fab={<Fab />}>
      <Helmet
        title={pageTitle}
        meta={[
          { name: 'description', content: excerpt(post.html, EXCERPT_LENGTH) },
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'og:title', content: pageTitle },
          { name: 'og:type', content: 'article' },
          { name: 'og:description', content: excerpt(post.html, EXCERPT_LENGTH) },
          { name: 'og:url', content: pageUrl },
          { name: 'og:image', content: post.frontmatter.eyeCatch },
          { name: 'og:site_name', content: site.siteMetadata.title },
          { name: 'og:locale', content: 'ja_JP' },
        ]}
      />
      <div id="fb-root"></div>
      <Container>
        <ContainerRow>
          <ContainerCol>
            <main>
              <article className="article">
                <EyeCatch src={post.frontmatter.eyeCatch} />
                <PubDate>{post.frontmatter.date}</PubDate>
                <Title>{post.frontmatter.title}</Title>
                <ArticleHeader>
                  <Author author={author} />
                  <Tags tags={post.frontmatter.tags} />
                </ArticleHeader>
                <Divider />
                {toc.length > 0 && (
                  <TocAccordion content={toc} title="目次" icon={<MapIcon />} />
                )}
                <ArticleBody html={post.html} />
                <Divider />

                {process.env.NODE_ENV !== 'development' && (
                  <div className="ad-wrapper">
                    <Ad
                      client={site.siteMetadata.adsenseClientId}
                      slot="9757270677"
                      type="landscape"
                    />
                  </div>
                )}

                <Divider />
                <ArticleFooter>
                  <Share url={pageUrl} title={pageTitle} />
                  <Tags tags={post.frontmatter.tags} />
                  {process.env.NODE_ENV !== 'development' && (
                    <Disqus
                      id={post.frontmatter.slug}
                      title={post.frontmatter.title}
                    />
                  )}
                </ArticleFooter>
              </article>
            </main>
            {toc.length > 0 && (
              <RelatedPosts
                posts={latestPosts.edges}
                title="最新記事"
                titleIcon={
                  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <linearGradient id="latestPosts" x1="0%" y1="0%" x2="60%" y2="100%">
                        <stop stopColor="#AC32E4" stopOpacity="1" offset="0%" />
                        <stop stopColor="#7918F2" stopOpacity="1" offset="48%" />
                        <stop stopColor="#4801FF" stopOpacity="1" offset="100%" />
                      </linearGradient>
                    </defs>
                    <g fill="url(#latestPosts)">
                      <path fillRule="evenodd" d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z"/>
                      <path d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z"/>
                    </g>
                  </svg>
                } />
            )}
            {relatedPosts.length > 0 && (
              <RelatedPosts
                posts={relatedPosts}
                title="関連記事"
                titleIcon={
                  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <linearGradient id="relatedPosts" x1="0%" y1="0%" x2="40%" y2="100%">
                        <stop stopColor="#AC32E4" stopOpacity="1" offset="0%" />
                        <stop stopColor="#7918F2" stopOpacity="1" offset="48%" />
                        <stop stopColor="#4801FF" stopOpacity="1" offset="100%" />
                      </linearGradient>
                    </defs>
                    <g fill="url(#relatedPosts)">
                      <path fillRule="evenodd" d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"/>
                      <path fillRule="evenodd" d="M8 4.41c1.387-1.425 4.854 1.07 0 4.277C3.146 5.48 6.613 2.986 8 4.412z"/>
                    </g>
                  </svg>
                }
              />
            )}
          </ContainerCol>
          <ContainerCol>
            <aside className="sidebar sidebar--sticky">
              {toc.length > 0 ? (
                <section className="sidebar__item show-xl-only">
                  <Toc content={toc} title="目次" icon={<MapIcon />} />
                </section>
              ) : (
                <Latest posts={latestPosts.edges} />
              )}

              {process.env.NODE_ENV !== 'development' && (
                <section className="sidebar__item">
                  <Ad
                    client={site.siteMetadata.adsenseClientId}
                    slot="7320208394"
                    type="landscape"
                  />
                </section>
              )}
            </aside>
          </ContainerCol>
        </ContainerRow>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PostByPath($slug: String!, $author: String!, $tags: [String]!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "YYYY.MM.DD")
        slug
        author
        title
        eyeCatch
        tags
      }
    }
    latestPosts: allMarkdownRemark(
      filter: { frontmatter: { public: { eq: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 6
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "YYYY.MM.DD")
            slug
            title
            eyeCatch
          }
        }
      }
    }
    relatedPosts: allMarkdownRemark(
      filter: {
        frontmatter: {
          public: { eq: true },
          tags: { in: $tags },
          slug: { ne: $slug }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 24
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            eyeCatch
          }
        }
      }
    }
    authorsJson(name: { eq: $author }) {
      name
      avatar
      twitter
    }
    site {
      siteMetadata {
        url
        title
        description
        adsenseClientId
      }
    }
  }
`
