import React from 'react'
import { Link } from 'gatsby'

export function writerLinkUrl (name) {
  return `/writers/${name.replace(' ', '+')}`
}

export default ({ name, className }) => (
  <Link
    className={className}
    to={writerLinkUrl(name)}
  >
    {name}
  </Link>
)
