import React, { useState, useRef } from 'react'
import { TocWrappper, TocTitle, TocList } from './toc'
import { VisibleLgBelow } from './visibility'
import Clickable from './clickable'

export default function TocAccordion({ content, title, icon }) {
  const [height, setHeight] = useState(0)
  const containerEl = useRef(null)

  const isOpen = () => height > 0
  const listHeight = () => containerEl.current.children[0].clientHeight
  const toggle = () => setHeight(isOpen() ? 0 : listHeight())

  return (
    <VisibleLgBelow>
      <TocWrappper accordion>
        <TocTitle title={title} icon={icon} />
        <div
          className="toc__accordion"
          style={{ height: `${height}px` }}
          ref={containerEl}
        >
          <TocList content={content} />
        </div>
        <Clickable className="toc__toggle" onClick={toggle}>
          {isOpen() ? 'Close' : 'Open'}
        </Clickable>
      </TocWrappper>
    </VisibleLgBelow>
  )
}
